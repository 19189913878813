import React from "react";

import telecoms from "../img/telecoms.svg";
import towerco from "../img/towerco.svg";
import pmeiot from "../img/pme-iot.svg";
import banquefinance from "../img/banque-finance.svg";
import cci from "../img/cci.png";
import has from "../img/has.png";
import unistra from "../img/unistra.png";
import anfsi from "../img/anfsi.png";
import retail from "../img/retail.svg";

import "../css/Temoignages.css";

const Temoignages = ({striped}) => (
    <div id='references' className={`section ${striped}`}>
        <div className='content'>
            <h1 className='sectiontitle'>
                <span className='red'>R</span>éférences
            </h1>


            <div className='temoignage reversed'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>T</span>ask force IA générative
                    </h1>
                    <h3 className='temoignage-subtitle'>Opérateur de télécommunications</h3>
                    <p>
                        Nous avons créé en Juin 2023 une <b>task force dédiée à l'IA Générative</b> pour transformer le service client d'un opérateur télécom majeur.
                        Elle est constituée de 2 ingénieurs IA et d'un chef de projet.
                        Elle identifie les cas d'usage avec les métiers, crée des prototypes, les industrialise à l'échelle et exploite l'infrastructure.
                        Elle se dédie principalement aux métiers du <b>service client</b>, avec des applications telles que
                        le résumé de conversation SAV, l'analyse de sentiment ou l'optimisation de la recherche de données.
                    </p>
                    <h3 className='temoignage-subtitle'>Réalisations</h3>
                    <ul>
                        <li>Création d'un middleware de service d'agents d'IA</li> 
                        <li>Réduction de 40% du temps de traitement des demandes SAV</li>
                        <li>Optimisation du knowledge management grâce à l'IA</li>
                    </ul>
                    <h3 className='temoignage-subtitle'>Pratique</h3>
                    <p>
                        Statut : projet démarré en septembre 2023, en cours, 900 JH<br />
                        Equipe : 3 ☥, méthodologie Agile<br />
                        Focus technique : Gemini, Vector Search, Node.js, Python, Docker, ElasticSearch, RabbitMQ, MongoDB
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={telecoms} alt='Visuel Télécoms' className='temoignage-photo' />
                </div>
            </div>


            <div className='temoignage'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>D</span>étection de fraudes sur photos de sites
                    </h1>
                    <h3 className='temoignage-subtitle'>Towerco</h3>
                    <p>
                        Notre client exploite des infrastructures passives (pylônes et toits-terrasses télécoms).
                        Nous avons développé une solution d'inspection de photos automatisée par IA pour optimiser la gestion d'actifs
                        et sécuriser les revenus sur un parc de 23 000 sites d'infrastructure télécom.
                    </p>
                    <h3 className='temoignage-subtitle'>Réalisations</h3>
                    <ul>
                        <li>Traitement de <b>600 000 photos</b> de sites </li>
                        <li>Classification automoatique des sites et enrichissement de la base métier</li>
                        <li>Identification de marques de corrosion pour anticiper les travaux de maintenance</li>
                        <li>Détectons de <b>fraudes</b> potentielles : copie de photos, recadrages, etc.</li>
                    </ul>
                    <h3 className='temoignage-subtitle'>Pratique</h3>
                    <p>
                        Statut : projet démarré en septembre 2024, en cours, 100 JH<br />
                        Equipe : 1,5 ☥, méthodologie RAD<br />
                        Focus technique : Pytorch, Transformer, Runpod, Voyage AI, Qdrant
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={towerco} alt='Visuel TowerCo' className='temoignage-photo' />
                </div>
            </div>

            
            <div className='temoignage reversed'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>S</span>elfcare client en langage naturel
                    </h1>
                    <h3 className='temoignage-subtitle'>PME industrielle</h3>
                    <p>
                        Notre client est spécialisé dans la <b>gestion d'équipements collectifs</b> grâce à l'IoT :
                        : ascenseurs, VMC, portes automatiques, installations solaires, etc.
                        Il met un portail à disposition des bailleurs et exploitants de sites
                        et leur donne accès à des statistiques sur leurs équipements.
                        
                        Nous avons intégré un module d'IA au portail client pour permettre la consultation
                        des données de contrat et d'IoT en toute autonomie.
                    </p>
                    <h3 className='temoignage-subtitle'>Réalisations</h3>
                    <ul>
                        <li>Ajout d'une interface conversationnelle dans le selfcare client</li>
                        <li>Transcription d'une demande en requêtes de bases de données</li>
                        <li>Affichage des résultats sous la forme d'indicateurs et téléchargement de fichier CSV</li>
                        <li>Réduction de 70% des sollicitations au support et amélioration de la satisfaction client</li>
                    </ul>
                    <h3 className='temoignage-subtitle'>Pratique</h3>
                    <p>
                        Statut : projet réalisé sur S2 2024, en maintenance, 100 JH<br />
                        Equipe : 2 ☥, méthodologie RAD<br />
                        Focus technique : Golang, Anthropic, Qdrant, PostgreSQL, Splunk, MongoDB, Docker, CL
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={pmeiot} alt='Visuel PME IoT' className='temoignage-photo' />
                </div>
            </div>


            <div className='temoignage'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>I</span>ndustrialisation de modèles d'IA
                    </h1>
                    <h3 className='temoignage-subtitle'>Secteur bancaire</h3>
                    <p>
                        Pour un groupe bancaire français, nous avons conçu une plateforme d'industrialisation des modèles d'IA.
                        Les 15 datascientists du datalab peuvent dorénavant gérer les versions de leurs modèles sur une interface,
                        déclencher des déploiements sur le SI bancaire et superviser les performances.
                        La complexité majeure résidait en la gestion de la scalabilité des infrastructures utilisées.
                    </p>
                    <h3 className='temoignage-subtitle'>Réalisations</h3>
                    <ul>
                        <li>Architecture et conception de l'infrastructure à déployer sur le cloud privé de la banque</li>
                        <li>Création d'interfaces graphiques d'upload et gestion des modèles</li>
                        <li>Création d'un service de déploiement automatique des modèles sous forme d'API conteneurisées</li>
                        <li>Monitoring des services et génération de statistiques de performances par modèle</li>
                    </ul>
                    <h3 className='temoignage-subtitle'>Focus technique</h3>
                    <p>
                        Statut : projet terminé en 2023, durée de 2 ans, 500 JH<br />
                        Equipe : 2 ☥, méthodologie RAD<br />
                        Focus technique : Python, Docker, Celery, Redis, Kube, Jenkins, React
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={banquefinance} alt='Visuel banque finance' className='temoignage-photo' />
                </div>
            </div>

            
            <div className='temoignage reversed'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>C</span>onseiller virtuel d'accompagnement à la création d'entreprise
                    </h1>
                    <h3 className='temoignage-subtitle'>Secteur public</h3>
                    <p>
                        Les CCI accompagnent les entrepreneurs de leur département dans la création ou la reprise de leur entreprise,
                        jusqu'à l'immatriculation.
                        Une CCI nous a sollicités afin de concevoir un conseiller virtuel capable de prendre en charge
                        une partie des demandes d'accompagnement.
                        Nous avons réalisé une plateforme web autonome connectée à plusieurs agents d'IA spécialisés.
                        La principale difficulté réside dans la multiplicité des réglementations locales ou des normes spécifiques
                        à certains métiers (taxis par exemple) et leur intégration dans le corpus documentaire à disposition du conseiller virtuel.
                    </p>
                    <h3 className='temoignage-subtitle'>Réalisations</h3>
                    <ul>
                        <li>Design et intégration d'un parcours de création par étapes dans une plateforme web</li>
                        <li>Création d'agents d'IA spécialisés : étude de marché, financement, statuts, immatriculation, etc.</li>
                        <li>Relecture et optimisation de documents de travail par les agents</li>
                        <li>Recommandation d'aides locales, départementales, régionales, nationales ou européennes cohérentes</li>
                        <li>Mise en relation avec des partenaires de la CCI pertinents pour le projet : banque, assurance, annonces légales, etc.</li>
                        <li>Homologation sécurité et RGPD</li>
                    </ul>
                    <h3 className='temoignage-subtitle'>Pratique</h3>
                    <p>
                        Statut : projet démarré en janvier 2024, en maintenance, durée d'un an / 170 JH<br />
                        Equipe : 2 à 3 ☥, méthodologie Agile<br />
                        Focus technique : Mistral, React, Nodejs, s3
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={cci} alt='Logo CCI' className='temoignage-photo' />
                </div>
            </div>


            <div className='temoignage'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                <h1 className='temoignage-title'>
                        <span className='red'>D</span>atavisualisation de l'enquête nationale e-satis
                    </h1>
                    <h3 className='temoignage-subtitle'>Haute Autorité de Santé</h3>
                    <p>
                        La HAS a mis en place depuis 2016 un système de mesure de la satisfaction des patients dans les établissements de santé.
                        Ce protocole nommé e-Satis consiste en un questionnaire adressé aux patients à l'issue de leur hospitalisation.
                        Les données extraites de ces questionnaires sont ensuite agrégées puis analysées afin de calculer des indicateurs de satisfaction.
                    </p>
                    <h3 className='temoignage-subtitle'>Réalisations</h3>
                    <p>
                        Nous avons développé une interface de datavisualisation des indicateurs e-satis
                        à destination des professionnels de santé et les responsables qualité dans les établissements de santé.

                        L'équipe Coddity est constituée d'une développeuse à plein temps et d'un leader technique à temps partiel.
                        Le projet de développement a duré 8 mois. L'application est dorénavant en production.

                    </p>
                    <h3 className='temoignage-subtitle'>Pratique</h3>
                    <p>
                        Statut : projet démarré en novembre 2023, en maintenance, 220 JH<br />
                        Equipe : 2 ☥, méthodologie Agile<br />
                        Focus technique : Typescript, React, Vite, Material UI, Nivo
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={has} alt='Logo HAS' className='temoignage-photo' />
                </div>
            </div>


            <div className='temoignage reversed'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                <h1 className='temoignage-title'>
                        <span className='red'>T</span>MA d'un ERP - Service de Formation Continue
                    </h1>
                    <h3 className='temoignage-subtitle'>Université de Strasbourg</h3>
                    <p>
                        Le Service Formation Continue (SFC) de l'Unistra pilote les activités de formation continue
                        de toutes les composantes de l'université.
                        60 personnes gèrent 900 formations professionnelles et académiques, animées par plus de 1000 intervenants,
                        pour 12 000 stagiaires et 10 M€ de chiffre d'affaires par an.
                    </p>
                    <h3 className='temoignage-subtitle'>Réalisations</h3>
                    <ul>
                        <li>Audit de code : dépendances, sécurité, qualité, tests, environnements, ci/cd</li>
                        <li>Développement de nouvelles fonctionnalités à la demande</li>
                        <li>Résolution d'anomalies</li>
                        <li>Maintenance des environnements</li>
                    </ul>
                    <h3 className='temoignage-subtitle'>Pratique</h3>
                    <p>
                        Statut : projet démarré en septembre 2024, en cours, budget de 160 JH<br />
                        Equipe : 3 ☥, méthodologie Agile<br />
                        Focus technique : Typescript, React, Next, MaterialUI
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={unistra} alt='Logo Formation Continue Université de Strasbourg' className='temoignage-photo' />
                </div>
            </div>


            <div className='temoignage'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                <h1 className='temoignage-title'>
                        <span className='red'>T</span>MA Renseignement
                    </h1>
                    <h3 className='temoignage-subtitle'>Gendarmerie Nationale - ANFSI</h3>
                    <p>
                        L'Agence du Numérique des Forces de Sécurité Intérieure (ex ST(SI)²)
                        développe et exploite des solutions numériques au profit de la population, des policiers et des gendarmes.

                        Ces applications nécessitent des travaux liées à l'adaptation au cadre réglementaire,
                        à l'évolution des pratiques en mobilité et à la maintenance préventive de sécurité.

                        Nous intervenons depuis 2022 au forfait directement intégrés dans les équipes techniques ANFSI.
                    </p>
                    <h3 className='temoignage-subtitle'>Réalisations</h3>
                    <p>
                        <li>Développement de fonctionnalités backend et frontend sur des applications historiques</li>
                        <li>Assistance à l'architecture et à la conception de nouvelles applications mobiles</li>
                        <li>Apport de bonnes pratiques méthodologiques</li>
                        <li>Relecture et audit de modules</li>
                    </p>
                    <h3 className='temoignage-subtitle'>Pratique</h3>
                    <p>
                        Statut : projet démarré en mars 2022, prestations livrées au fil de l'eau, 200 JH<br />
                        Equipe : 2 à 4 ☥, méthodologie Agile<br />
                        Focus technique : Java8, Spring MVC et Batch, AngularJS, PostgreSQL, Elasticsearch, OrientDB, React, NextJS
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={anfsi} alt='Logo ANFSI' className='temoignage-photo' />
                </div>
            </div>


            <div className='temoignage reversed'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>R</span>efonte d'un module de SIRH
                    </h1>
                    <h3 className='temoignage-subtitle'>Groupe de retail français</h3>
                    <p>
                        Notre client gère des commerces de détail en contexte de voyage : aéroports, gares, stations-service, etc.                        
                        Afin de piloter la masse salariale, les dépenses des points de vente, la conformité vis à vis des conventions collectives
                        et le versement des salaires, elle a développé un client lourd qu'il a été nécessaire de remplacer.
                        Nous avons re développé de zéro une nouvelle plateforme totalement intégrée au SI client.
                    </p>
                    <h3 className='temoignage-subtitle'>Réalisations</h3>
                    <ul>
                        <li>Animation d'ateliers UX et design des interfaces</li>
                        <li>Développement technique et recette</li>
                        <li>Animation d'une communauté d'utilisateurs pilotes</li>
                        <li>Livraisons et animation de phases de test utilisateurs</li>
                        <li>Exploitation et maintenance</li>
                        <li>Conduite du changement</li>
                    </ul>
                    <h3 className='temoignage-subtitle'>Pratique</h3>
                    <p>
                        Statut : projet démarré en novembre 2023, en maintenance, 300 JH<br />
                        Equipe : 4 ☥, méthodologie Agile<br />
                        Focus technique : Typescript, Nuxt, Azure, SQL Server
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={retail} alt='Visuel Retail' className='temoignage-photo' />
                </div>
            </div>



        </div>
    </div>
    );

export default Temoignages;
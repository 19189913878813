// -------------------------------------------------------------------------
// Librairies

import React from 'react';

// -------------------------------------------------------------------------
// Sous composants

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faTwitter, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { NavHashLink as Link } from 'react-router-hash-link';

import "../css/Footer.css";

const Footer = () => (
  <div>
    <footer>
      <span className="grey footer">
        <ul className="footer-infos">
          <li>{new Date().getFullYear()} <Link smooth to='/#home'> <span className="red"> C</span>oddity</Link></li>
          <li>37bis rue de Montreuil, 75011 Paris</li>
          <li><Link to='/mentions-legales' activeClassName="selected" className="pointer">Mentions Légales</Link></li>
          <li><Link to='/devenir-artisan' activeClassName="selected" className="pointer">Devenir Artisan</Link></li>
          <li><Link to='/contact' activeClassName="selected" className="pointer">Nous Contacter</Link></li>
        </ul>
      </span>
      <ul className="socialnetwork">
        <li><a className="pointer" href="https://www.linkedin.com/company/coddity" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedinIn}/></a></li>
      </ul>
    </footer>
  </div>
);

export default Footer;

import React from 'react';
import LogoItem from './logoitem/LogoItem';

import { ConsultingSvg, QualiteSvg } from '../assets/svg/';
import PrototypeSvg from '../img/prototype.svg';

import "../css/project-grid.css";

const Services = ({striped}) => (
    <div id="services" className={`section ${striped}`}>
        <div className="content">
            <h1 className="sectiontitle">
                Services
            </h1>
            <div className="block-content">

                <div className="project-grid project-grid-max-3">

                    <div className="project-grid-item center">
                        
                        <LogoItem imgSvg={PrototypeSvg} />
                        
                        <h1>IA Studio</h1>
                        <p className="pad-text">
                            Nous <b>créons des agents IA sur-mesure</b> pour vos besoins métier.
                            Notre expertise couvre la conception, l'UX, le développement Agile
                            et le déploiement d'applications.
                            Nous assurons un accompagnement complet 
                            de l'analyse des besoins à la maintenance,
                            en passant par la conduite du changement.
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        
                        <QualiteSvg />
                        
                        <h1>IA Transform</h1>
                        <p className="pad-text">
                            <b>Préparez votre organisation à l'ère de l'IA</b>.
                            Nous auditons et optimisons vos actifs d'entreprise pour maximiser leur potentiel IA.
                            Notre approche structurée transforme votre documentation,
                            adapte vos processus et forme vos équipes pour une transition réussie vers l'IA.
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        
                        <ConsultingSvg />
                        
                        <h1><span className='red'>C</span>onseil</h1>
                        <p className="pad-text">
                            Nos experts vous accompagnent dans votre <b>stratégie IA</b>.
                            Nous auditons le comportement de vos agents et vous guidons dans la conformité réglementaire,
                            la gestion des risques et la gouvernance.
                            Notre expertise garantit une adoption éthique et responsable de l'IA dans votre organisation.
                        </p>
                    </div>                 

                </div>
            </div>

        </div>
    </div>
);

export default Services;

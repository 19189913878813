import React from 'react';

import LogoItem from "./logoitem/LogoItem";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import LogoMajor from '../img/major.svg';
import { DanilSvg } from '../assets/svg/';
import LogoShaker from '../img/ginto.svg';
import LogoSara from '../img/sara.svg';
import LogoAogenia from '../img/aogenia.svg';

import "../css/project-grid.css";

const Produits = ({striped}) => (
    <div id="produits" className={`section ${striped}`}>
        <div className="content">
            <h1 className="sectiontitle">
                Produits SaaS
            </h1>
            <div className="block-content">
                <div className="project-grid">

                    <div className="project-grid-item center">
                        <LogoItem imgSvg={LogoMajor} />
                        <h1>Major</h1>
                        <p className="pad-text">
                            Nous avons conçu notre propre <b>socle serviciel</b> d'IA.
                            Major intègre des capacités multimodales avancées (texte, image, voix),
                            supervise les agents IA, orchestre leurs traitements
                            et assure un monitoring continu des performances et des biais.
                        </p>
                        <p className="pad-text">
                            <a href="mailto:contact@coddity.com" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-center sendbtn">
                                    En savoir plus <FontAwesomeIcon icon={faEnvelope} className="iconMargin" />
                                </button>
                            </a>
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        <DanilSvg />
                        <h1>Danil</h1>
                        <p className="pad-text">
                            Danil analyse et améliore la performance de vos agents conversationnels en visualisant
                            le déroulement des conversations tout en mesurant la <b>satisfaction client</b> en temps réel.
                            Danil traite aujourd'hui près d'1 million de conversations mensuelles.
                        </p>
                        <p className="pad-text">
                            <a href="https://danil.io/" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-center blackbtn">
                                    danil.io <FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                                </button>
                            </a>
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        <LogoItem imgSvg={LogoShaker} />
                        <h1>Ginto</h1>
                        <p className="pad-text">
                            Plateforme de <b>micro-learning</b> co-construite avec l'agence de création
                            de contenus <a href="http://pikel.fr/" target="_blank" rel="noopener noreferrer">Pikel</a>,
                            Ginto favorise l'apprentissage grâce à un gameplay et des quizz intelligents.
                            Utilisée par 30 000 collaborateurs du secteur banque assurance.
                        </p>
                        <p className="pad-text">
                            <a href="https://ginto.ink/" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-center greenbtn">
                                    ginto.ink <FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                                </button>
                            </a>
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        <LogoItem imgSvg={LogoSara} />
                        <h1>S.A.R.A.</h1>
                        <p className="pad-text">
                            S.A.R.A. est un agent d'IA dédié au <b>staffing</b> : recrutement, GPEC et mobilité interne.
                            Elle identifie les meilleurs profils pour une fiche de poste, cartographie les compétences
                            et prépare les trames d'entretiens de qualification.
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        <LogoItem imgSvg={LogoAogenia} />
                        <h1>Aogenia</h1>
                        <p className="pad-text">
                            Aogenia est un agent ingénieur d'affaires qui fait gagner du temps aux équipes avant-vente.
                            Il analyse les <b>appels d'offres</b> du secteur, sélectionne les plus adressables
                            et prépare les trames de propales en conséquence.
                            
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
);

export default Produits;

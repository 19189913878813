import React from 'react';

import "../css/project-grid.css";

import LogoItem from "./logoitem/LogoItem";

import { WebdevSvg, CloudSvg, ServerSvg, HalSvg } from '../assets/svg/';

import ChatGptSvg from '../img/chatgpt.svg';
import PromptSvg from '../img/prompt.svg';
import GdprSvg from '../img/gdpr.svg';



const SavoirFaire = ({striped}) => (  
  <div id="savoirfaire" className={`section ${striped}`}>
    <div className="content">
      <h1 className="sectiontitle">Savoir<span className="red"> - </span>faire</h1>
      <div className="block-content">

        <div className="project-grid project-grid-max-4">


          <div className="project-grid-item center">
            
            <LogoItem imgSvg={ChatGptSvg} />
            
            <h1><span className="red">E</span>xpertise LLM</h1>
            <p className="pad-text">
              Nous testons et finetunons des modèles en permanence : modèles propriétaires (Mistral, Anthropic Claude, Google Gemini),
              et modèles open source (Florence, Flux, Whisper, Nemo, Qwen)
              <br /><br />
              <b>Anthropic</b>, <b>Mistral</b>, <b>Whisper</b>, <b>Florence</b>
            </p>
          </div>
        
          <div className="project-grid-item center">
            
            <HalSvg />
            
            <h1><span className="red">S</span>upervision IA</h1>
            <p className="pad-text">
              Nous avons développé notre propre framework d'exploitation de modèles avec
              capacité multimodale (texte, image, voix), gestion
              native de la charge, pipeline d'évaluation en temps réel,
              génération de métriques de qualité et contrôle des biais.
            </p>
          </div>

          <div className="project-grid-item center">
            
            <LogoItem imgSvg={PromptSvg} />
            
            <h1>Prompt engineering</h1>
            <p className="pad-text">
              Nous concevons et optimisons des prompts performants pour vos cas d'usage spécifiques.
              Notre expertise permet de maximiser la pertinence des réponses et d'assurer un contrôle précis des sorties des modèles.
            </p>
          </div>

          <div className="project-grid-item center">
            
            <WebdevSvg />
            
            <h1>Prototypage</h1>
            <p className="pad-text">
              Nous utilisons des LLMs as a service, notre expertise des prompts 
              et des outils de prompt-to-code / low-code pour développer rapidement des prototypes.
              <br /><br />
              <b>Major</b>, <b>V0.dev</b>, <b>n8n</b>
            </p>
          </div>

          <div className="project-grid-item center">
            
            <ServerSvg />
            
            <h1>Industrialisation</h1>
            <p className="pad-text">
              Nous industrialisons nos prototypes grâce à nos experts logiciel : API, frontend,
              interconnexions aux SI, authentification, testing, documentation, etc.
              <br /><br />
              <b>Typescript</b>, <b>Golang</b>, <b>React</b>, <b>Docker</b>
            </p>
          </div>

          <div className="project-grid-item center">
            
            <CloudSvg />
            
            <h1><span className="red">E</span>xploitation</h1>
            <p className="pad-text">
              Nous hébergeons nos produits chez des cloud providers privés ou publics.
              Nos services sont supervisés, journalisés et sécurisés.
              <br /><br />
              <b>Azure</b>, <b>Aws</b>, <b>Gcp</b>, <b>Runpod.io</b>
            </p>
          </div>

          <div className="project-grid-item center">
            
            <LogoItem imgSvg={GdprSvg} />
            
            <h1>Conformité</h1>
            <p className="pad-text">
              Nous pilotons les projets d'IA dédiés à nos clients dans le respect du cadre réglementaire : 
              analyse des risques, dossiers d'architecture, homologation sécurité et conformité IA.
              <br /><br />
              <b>Agile</b>, <b>Ai Act</b>, <b>Rgpd</b>, <b>Ssi</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SavoirFaire;

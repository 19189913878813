import React from "react";

import { NavHashLink as Link } from 'react-router-hash-link';

import { HomeLogoSvg } from "../assets/svg/";

import "../css/Home.css";

const Home = ({striped}) => (
    <div className={`section ${striped}`} id='home'>
        <div className='content'>
            <div className="center">
                <div className="logohome">
                    <HomeLogoSvg />
                </div>

                <h1 className='baseline'>
                    <Link to="/#maison" smooth duration={500}>{"{ Manufacture d'agents d'IA }"}</Link>
                </h1>
            </div>
        </div>
    </div>
);

export default Home;
